<template>
  <div id="counter2" :class="{time_counter:true, w60:$API.util.isMobile()}" v-show="showTemplate">
    <div class="base-timer">
      <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g class="base-timer__circle">
          <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
          <path
              id="base-timer-path-remaining"
              stroke-dasharray="0 283"
              class="base-timer__path-remaining"
              :d="remainingPath"
          ></path>
        </g>
      </svg>
      <span id="base-timer-label" class="base-timer__label" :class="{'state-end': showEndString, 'state-betting': !showEndString}" v-html="timeLeft"></span>
      <span id="base-timer-sec" class="base-timer__sec red"></span>
    </div>
  </div>
</template>

<style scoped>
  .state-end {
    top: 10px;
    font-size: 24px;
    color:#fff;
    font-weight:bold;
    text-shadow:1px 1px #000;
  }

  .state-betting {
    top: 6px;
  }

  @media screen and (max-width: 768px){
    .state-end {
      top: 3px;
      font-size: 18px;
      color:#fff;
      font-weight:bold;
      text-shadow:1px 1px #000;
    }

    .state-betting {
      top: 3px;
    }
  }
</style>

<script>
export default {
  name: 'radial-progress-bar-component',
  props: {
    multi: Boolean,
    room: Object
  },
  data () {
    return {
      showTemplate: true,
      showEndString: true,
      FULL_DASH_ARRAY: 283,
      COLOR_CODES: {
        info: {
          color: "green"
        },
        warning: {
          color: "orange",
          threshold: 10
        },
        alert: {
          color: "red",
          threshold: 5
        }
      },
      TIME_LIMIT: (!this.room || !this.room.game) ? 30 : this.room.game.second,
      timePassed: 0,
      timeLeft: '베팅<br />종료', //(!this.room || !this.room.game) ? 30 : this.room.game.second,
      tmTimer: null,
      timerTitle: "종료",
    }
  },
  computed: {
    remainingPath: function() {
      return this.$API.util.isMobile() && this.$API.util.isIPhone() ?
          (window.innerWidth < 390 ? "M 50, 93 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0" :
              "M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0") :
          "M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0";
    }
  },
  methods: {
    initTimerState: function () {
      if (this.tmTimer != null) {
        this.timePassed = 0;
        clearInterval(this.tmTimer);
        this.tmTimer = null;
        this.timerTitle = "종료";
      }
      this.clearTimer();
      // this.showTemplate = false
      this.room && (this.TIME_LIMIT = this.room.game.second)
    },
    formatTime: function(time) {
      let seconds = time % 60;
      // if (seconds < 10) {
      //     seconds = `0${seconds}`;
      // }
      return `${seconds}`;
    },
    calculateTimeFraction: function() {
      const rawTimeFraction = this.timeLeft / this.TIME_LIMIT;
      return rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction);
    },
    setCircleDasharray: function() {
      const circleDasharray = `${(
          this.calculateTimeFraction() * this.FULL_DASH_ARRAY
      ).toFixed(0)} 283`;

      const obj = document.getElementById('base-timer-path-remaining')
      if (obj) {
        obj.setAttribute("stroke-dasharray", this.timeLeft > 0 ? circleDasharray : '0 283');
      }
    },
    // eslint-disable-next-line no-unused-vars
    setRemainingPathColor: function(timeLeft) {
      // const { alert, warning, info } = this.COLOR_CODES;

      // const obj1 = document.getElementById('base-timer-path-remaining')
      const obj2 = document.getElementById('base-timer-label')
      // const obj3 = document.getElementById('base-timer-sec')

      obj2.innerHTML = this.formatTime(this.TIME_LIMIT)
      /*
      if (timeLeft <= alert.threshold) {
        obj1.classList.remove(warning.color)
        obj1.classList.add(alert.color)

        obj2.classList.remove(warning.color)
        obj2.classList.add(alert.color);

        obj3.classList.remove(warning.color)
        obj3.classList.add(alert.color);

      } else if (timeLeft <= warning.threshold) {
        obj1.classList.remove(info.color)
        obj1.classList.add(warning.color)

        obj2.classList.remove(info.color)
        obj2.classList.add(warning.color)

        obj3.classList.remove(info.color)
        obj3.classList.add(warning.color)
      }
       */
    },
    startTimerCount: function () {
      let t = this;

      const obj1 = document.getElementById('base-timer-path-remaining')
      const obj2 = document.getElementById('base-timer-label')
      const obj3 = document.getElementById('base-timer-sec')

      obj1.setAttribute('class', 'base-timer__path-remaining')
      obj1.classList.add(this.COLOR_CODES.info.color);

      obj2.setAttribute('class', 'base-timer__label')
      obj2.classList.add(this.COLOR_CODES.info.color);

      obj3.setAttribute('class', 'base-timer__sec')
      obj3.classList.add(this.COLOR_CODES.info.color);

      //$(this.$el).fadeIn(300);
      this.showTemplate = true
      this.tmTimer = setInterval(() => {
        t.timePassed++;
        t.timeLeft = t.TIME_LIMIT - t.timePassed;

        // obj2.innerHTML = t.formatTime(t.timeLeft)

        /*if (!this.$API.util.isMobile()) {
          t.setCircleDasharray();
        }*/
        t.setCircleDasharray();
        t.setRemainingPathColor(t.timeLeft);

        // if (t.timeLeft > 4 && t.timeLeft <= 10 && !t.multi) {
        if (t.timeLeft <= 5) {
          this.$API.util.playSoundBeep()
        }

        if (t.timeLeft <= 0) {
          // t.timeLeft = '베팅<br />종료'
          t.clearTimer();
          //$(t.$el).fadeOut(300);
          // this.showTemplate = false
          clearInterval(t.tmTimer);
          t.tmTimer = null;
          t.timePassed = 0;
          t.timerTitle = "베팅<br>종료";
        }
      }, 1000);
    },
    clearTimer: function() {
      this.showEndString = true
      // this.timeLeft = 0;
      //타이머 텍스트
      this.timeLeft = "베팅<br/>종료";
      // const obj2 = document.getElementById('base-timer-label')
      // obj2.innerHTML = this.formatTime(this.timeLeft)
      if (!this.$API.util.isMobile()) {
        this.setCircleDasharray();
      }
      this.setRemainingPathColor(0);
    },
    onStartBet: function (time) {
      if (this.tmTimer == null) {
        this.TIME_LIMIT = this.timeLeft = time;
        if (!this.$API.util.isMobile()) {
          this.setCircleDasharray();
        }
        this.setRemainingPathColor(this.timeLeft);
        this.timerTitle = this.timeLeft.toString();

        this.startTimerCount();
      }

      this.showEndString = false
    },
    onBettingTime: function (time) {
      if (this.tmTimer == null || time < 1) return;
      this.TIME_LIMIT = time + this.timePassed;

    },
    onStartRound: function () {
      if (this.tmTimer != null) {
        //$(this.$el).fadeOut(300);
        this.clearTimer();
        clearInterval(this.tmTimer);
        this.tmTimer = null;
        this.timePassed = 0;
        this.timerTitle = "베팅<br>종료";
      }
    },
    onRoundResult: function () {
      this.timerTitle = "종료";
    }
  },
  created: function () {

  },
  unmounted: function () {
    if(this.tmTimer != null) {
      clearInterval(this.tmTimer);
    }
  }
}
</script>