const COLOR_PLAYER = "#0055ff";
const COLOR_BANKER = "#ff0000";
const COLOR_LINE = "#e5e5e5";
const COLOR_TIE = "#5baf00";
const TEXT_PLAYER = "P";
const TEXT_BANKER = "B";
const TEXT_TIE = "T"

export default class HistorySixDrawer {
    constructor(api) {
        this.$API = api
        this.ctx = null
        this.x = 0
        this.y = 0
        this.width = 0
        this.height = 0
        this.cols = 0
        this.rows = 0
        this.cellPadding = 2.5
        this.colors = [COLOR_PLAYER, COLOR_BANKER, COLOR_TIE]
        this.texts = [TEXT_PLAYER, TEXT_BANKER, TEXT_TIE]
        this.drawCells = null
        this.pCount = 0
        this.bCount = 0
        this.tCount = 0
        this.bpCount = 0
        this.ppCount = 0
    }

    cellWidth () {
        return this.width / this.cols;
    }
    cellHeight () {
        return this.height / this.rows;
    }

    init (ctx, x, y, w, h, cols, rows) {
        this.ctx = ctx;
        this.x = x;
        this.y = y;
        this.width = w;
        this.height = h;
        this.cols = cols;
        this.rows = rows;
        this.ctx.clearRect(this.x, this.y, this.width, this.height);
        let i;

        this.ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = COLOR_LINE;
        for (i = 0; i < this.cols; i++) {
            ctx.moveTo(this.x + this.cellWidth() * (i + 1), this.y);
            ctx.lineTo(this.x + this.cellWidth() * (i + 1), this.y + this.height);
            ctx.stroke();
        }
        for (i = 0; i < this.rows; i++) {
            ctx.moveTo(this.x, this.y + this.cellHeight() * (i + 1));
            ctx.lineTo(this.x + this.width, this.y + this.cellHeight() * (i + 1));
            ctx.stroke();
        }
    }

    redraw (hists, isDT) {

        this.drawCells = [];
        let i;
        let curPos = { row: 0, col: 0 };
        this.pCount = 0
        this.bCount = 0
        this.tCount = 0
        this.ppCount = 0
        this.bpCount = 0

        for (i = 0; i < hists.length; i++) {
            if (void 0 === this.drawCells[curPos.col]) this.drawCells[curPos.col] = [];
            let winTarget = -1
            if (hists[i] === '10' || hists[i] === '11' || hists[i] === '12' || hists[i] === '13') {
                winTarget = 1
                this.bCount++
            } else if (hists[i] === '20' || hists[i] === '21' || hists[i] === '22' || hists[i] === '23') {
                winTarget = 2
                this.tCount++
            } else if (hists[i] === '30' || hists[i] === '31' || hists[i] === '32' || hists[i] === '33') {
                winTarget = 0
                this.pCount++
            }

            const isPP = hists[i] === '12' || hists[i] === '13' || hists[i] === '22' || hists[i] === '23' || hists[i] === '32' || hists[i] === '33'
            const isBP = hists[i] === '11' || hists[i] === '13' || hists[i] === '21' || hists[i] === '23' || hists[i] === '31' || hists[i] === '33'

            if (isPP) this.ppCount++
            if (isBP) this.bpCount++

            this.drawCells[curPos.col][curPos.row] = {
                result: winTarget,
                // cards: [hists[i][0], hists[i][1], hists[i][2], hists[i][3], hists[i][4], hists[i][5]],
                ppair: isPP,
                bpair: isBP
            }
            curPos.row++;
            if (curPos.row >= this.rows) {
                curPos.col++;
                curPos.row = 0;
            }
        }

        this.colors = [COLOR_PLAYER, COLOR_BANKER, COLOR_TIE];
        this.texts = [TEXT_PLAYER, TEXT_BANKER, TEXT_TIE];

        let iDeltaCol = (this.drawCells.length > this.cols) ? this.drawCells.length - this.cols : 0;

        // const self = this
        for (let iCol = iDeltaCol; iCol < this.drawCells.length; iCol++) {
            for (let iRow = 0; iRow < this.drawCells[iCol].length; iRow++) {
                let clr, txt;

                if (void 0 === this.drawCells[iCol][iRow] || void 0 === (clr = this.colors[this.drawCells[iCol][iRow].result]) || void 0 === (txt = this.texts[this.drawCells[iCol][iRow].result])) continue;

                let x = this.cellWidth() / 2 + (iCol - iDeltaCol) * this.cellWidth();
                let y = this.cellHeight() / 2 + iRow * this.cellHeight();

                this.$API.util.drawCircle(this.ctx, this.x + x, this.y + y, this.cellWidth() / 2 - this.cellPadding, 0, clr, true);
                this.$API.util.drawText(this.ctx, this.x + x, this.y + y + 2, txt, this.cellWidth() / 2, "#ffffff");

                /*
                const img = new Image()
                img.onload = function() {
                    self.ctx.drawImage(img, self.x + x, self.y + y, self.cellWidth() - self.cellPadding, self.cellHeight() - self.cellPadding)
                }
                img.src = '/imgs/history/icon_P.png'

                console.log(clr, txt)
                 */

                if (!isDT) {
                    if (this.drawCells[iCol][iRow].ppair) {
                        this.$API.util.drawCircle(this.ctx, this.x + (iCol + 1 - iDeltaCol) * this.cellWidth() - this.cellPadding * 2, this.y + (iRow + 1) * this.cellHeight() - this.cellPadding * 2, this.cellWidth() * 0.12, 0, "#ffffff", true);
                        this.$API.util.drawCircle(this.ctx, this.x + (iCol + 1 - iDeltaCol) * this.cellWidth() - this.cellPadding * 2, this.y + (iRow + 1) * this.cellHeight() - this.cellPadding * 2, this.cellWidth() * 0.07, 0, COLOR_PLAYER, true);
                    }
                    if (this.drawCells[iCol][iRow].bpair) {
                        this.$API.util.drawCircle(this.ctx, this.x + (iCol - iDeltaCol) * this.cellWidth() + this.cellPadding * 2, this.y + iRow * this.cellHeight() + this.cellPadding * 2, this.cellWidth() * 0.12, 0, "#ffffff", true);
                        this.$API.util.drawCircle(this.ctx, this.x + (iCol - iDeltaCol) * this.cellWidth() + this.cellPadding * 2, this.y + iRow * this.cellHeight() + this.cellPadding * 2, this.cellWidth() * 0.07, 0, COLOR_BANKER, true);
                    }
                }
            }
        }
    }
}