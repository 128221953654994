const COLOR_PLAYER = "#0055ff";
const COLOR_BANKER = "#ff0000";
const COLOR_LINE = "#e5e5e5";
const COLOR_TIE = "#5baf00";

const BREAK_LEFTORRIGHT = 1;

const RESULT_PLAYER = 0;
const RESULT_BANKER = 1;
const RESULT_TIE = 2;

export default class HistorySixDrawer {
    constructor(api) {
        this.$API = api
        this.ctx = null
        this.x = 0
        this.y = 0
        this.width = 0
        this.height = 0
        this.cols = 0
        this.rows = 0
        this.colors = [COLOR_PLAYER, COLOR_BANKER, COLOR_TIE]
        this.showBase = 0
        this.deltaCols = 0
        this.breakLeftOrRight = BREAK_LEFTORRIGHT
        this.cellPadding = 1
        this.calcCells = null
        this.drawCells = null
        this.lineWidth = 1
    }

    cellWidth () {
        return this.width / this.cols;
    }
    cellHeight () {
        return this.height / this.rows;
    }

    init (ctx, x, y, w, h, cols, rows, showBase) {
        this.ctx = ctx;
        this.x = x;
        this.y = y;
        this.width = w;
        this.height = h;
        this.cols = cols;
        this.rows = rows;
        this.showBase = showBase;
        this.ctx.clearRect(this.x, this.y, this.width, this.height);
        let i;

        this.ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = COLOR_LINE;
        for (i = 0; i < this.cols; i += 2) {
            ctx.moveTo(this.x + this.cellWidth() * (i + 2), this.y);
            ctx.lineTo(this.x + this.cellWidth() * (i + 2), this.y + this.height);
            ctx.stroke();
        }
        for (i = 0; i < this.rows; i += 2) {
            ctx.moveTo(this.x, this.y + this.cellHeight() * (i + 2));
            ctx.lineTo(this.x + this.width, this.y + this.cellHeight() * (i + 2));
            ctx.stroke();
        }
    }

    getNextRowCol (tf, pos) {
        if (tf) {
            pos.row++;
            if (pos.row >= this.rows || void 0 !== this.drawCells[pos.col][pos.row] || this.deltaCols > 0) {
                pos.row--;
                pos.col += this.breakLeftOrRight;
                this.deltaCols++;
                // 만일 왼쪽으로 꺽이다가 왼쪽 끝에 도달햇거나 혹은 꺽이여가는 쪽에 이미 셀이 존재하면 반대방향으로 뻗어나간다.
                if (pos.col < 1 || (this.drawCells[pos.col] !== void 0 && this.drawCells[pos.col][pos.row] !== void 0) ||
                    (this.drawCells[pos.col + this.breakLeftOrRight] !== void 0 && this.drawCells[pos.col + this.breakLeftOrRight][pos.row] !== void 0)) {
                    // 이미 꺾인 셀들을 반대방향으로 돌린다.
                    for (let i = 1; i < this.deltaCols; i++) {
                        var cell = this.drawCells[pos.col - i * this.breakLeftOrRight][pos.row];
                        if (this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight] === void 0) this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight] = [];
                        this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight][pos.row] = cell;
                        this.drawCells[pos.col - i * this.breakLeftOrRight][pos.row] = undefined;
                    }
                    // 계산할때 이용하는 변수는 반대로 설정한다.
                    this.breakLeftOrRight = this.breakLeftOrRight == 1 ? -1 : 1;

                    pos.col += 2 * this.deltaCols * this.breakLeftOrRight;
                }
            }
        } else {
            pos.col = pos.col - this.breakLeftOrRight * this.deltaCols + 1;
            pos.row = 0;
            this.deltaCols = 0;
            // 결과가 꺽이엿으면 방향을 다시 본래대로 설정한다.
            this.breakLeftOrRight = BREAK_LEFTORRIGHT;
        }
    }

    updateCells (origins) {
        let curPos = { col: 0, row: 0 };
        this.calcCells = [];
        let oldCell = null;

        for (let iCol = this.showBase + ((origins.length > 0 && origins[0].length > 0 && origins[0][0].result == RESULT_TIE) ? 1 : 0); iCol < origins.length; iCol++) {
            let iRow = 0;
            do {
                let baseLink = false;
                // 만일 앞열에 결과가 존재하면 앞열의 결과를 얻는다. 앞열이 이어졋으면 긍정으로 표시한다.
                if (origins[iCol - this.showBase][iRow] !== void 0) {
                    if (origins[iCol - this.showBase][iRow + 1] !== void 0) baseLink = true;
                } else {
                    // 앞열의 결과가 없으면 이어진것으로 판단한다.
                    baseLink = true;
                }

                let link = false;
                // 기본계산대상으로부터 이어졋는지를 판단한다.
                // 만일 마지막요소에 도달했으면 리턴한다.
                if (origins.length <= iCol + 1 && origins[iCol][iRow + 1] === void 0) break;

                if (origins[iCol][iRow + 1] !== void 0) link = true;

                let iResult = baseLink == link ? RESULT_BANKER : RESULT_PLAYER;
                if (oldCell != null) {
                    if (oldCell.result == iResult) {
                        curPos.row++;
                    } else {
                        curPos.col++;
                        curPos.row = 0;
                    }
                }
                if (this.calcCells[curPos.col] === void 0) this.calcCells[curPos.col] = [];
                oldCell = this.calcCells[curPos.col][curPos.row] = { result: iResult };
                iRow++;
            } while (origins[iCol][iRow] != undefined);
        }
    }

    redraw (origins) {
        this.updateCells(origins);
        this.drawCells = [];
        this.deltaCols = 0;

        let iOrgCol, iOrgRow;
        let iPrevResult = -1;
        let curPos = { row: 0, col: 0 };
        for (iOrgCol = 0; iOrgCol < this.calcCells.length; iOrgCol++) {
            for (iOrgRow = 0; iOrgRow < this.calcCells[iOrgCol].length; iOrgRow++) {
                if (iPrevResult >= 0) {
                    this.getNextRowCol(iPrevResult == this.calcCells[iOrgCol][iOrgRow].result, curPos);
                }
                if (void 0 === this.drawCells[curPos.col]) this.drawCells[curPos.col] = [];
                this.drawCells[curPos.col][curPos.row] = this.calcCells[iOrgCol][iOrgRow];
                iPrevResult = this.calcCells[iOrgCol][iOrgRow].result;
            }
        }
        this.colors = [COLOR_PLAYER, COLOR_BANKER];

        let iDeltaCol = (this.drawCells.length > this.cols) ? this.drawCells.length - this.cols : 0;
        for (let iCol = iDeltaCol; iCol < this.drawCells.length; iCol++) {
            for (let iRow = 0; iRow < this.drawCells[iCol].length; iRow++) {
                let clr;
                if (void 0 === this.drawCells[iCol][iRow] || void 0 === (clr = this.colors[this.drawCells[iCol][iRow].result])) continue;

                let x = this.cellWidth() / 2 + (iCol - iDeltaCol) * this.cellWidth();
                let y = this.cellHeight() / 2 + iRow * this.cellHeight();

                if (this.showBase == 1) {
                    this.$API.util.drawCircle(this.ctx, this.x + x, this.y + y, this.cellWidth() / 2 - this.cellPadding, this.lineWidth, clr);
                } else if (this.showBase == 2) {
                    this.$API.util.drawCircle(this.ctx, this.x + x, this.y + y, this.cellWidth() / 2 - this.cellPadding, 0, clr, true);
                } else if (this.showBase == 3) {
                    //this.$API.util.drawLine(this.ctx, this.x + (iCol - iDeltaCol) * this.cellWidth() + this.cellPadding, this.y + (iRow + 1) * this.cellHeight() - this.cellPadding,
                    //    this.x + (iCol + 1 - iDeltaCol) * this.cellWidth() - this.cellPadding, this.y + iRow * this.cellHeight() + this.cellPadding, this.lineWidth, clr);

                    this.$API.util.drawLine(this.ctx,
                        this.x + (iCol + 1 - iDeltaCol) * this.cellWidth() - this.cellPadding,
                        this.y + (iRow + 1) * this.cellHeight() - this.cellPadding,
                        this.x + (iCol - iDeltaCol) * this.cellWidth() + this.cellPadding,
                        this.y + iRow * this.cellHeight() + this.cellPadding,
                        this.lineWidth, clr);
                }
            }
        }
    }
}