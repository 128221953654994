<template>
  <div class="game_coin" id="game_coin"> <!-- 배팅칩 -->
    <ul class="kor">
      <li :class="{
        'c1000': chipIdx === 0,
        'c10000': chipIdx === 1,
        'c50000': chipIdx === 2,
        'c100000': chipIdx === 3,
        'c500000': chipIdx === 4,
        'c1000000': chipIdx === 5,
        'on': chipIdx === selectedChipIndex

      }" :data-chip="chip" v-for="(chip, chipIdx) in chips" v-bind:key="'chipIndex-' + chipIdx" @click="changeChipIndex(chipIdx)">
        <span>{{ $API.util.getChipMoneyUnit(chip) }}</span>
      </li>
    </ul>
  </div><!-- 배팅칩 -->
</template>

<script>

export default {
  name: 'room-chip-panel-component',
  props: {
    chips: Object,
    chipIndex: Number
  },
  data () {
    return {
      isMultiRoom: false,
      isWideView : false,
      selectedChipIndex: 0,
    }
  },
  computed: {
    chipClass: function() {
      return !this.isMultiRoom && this.isWideView ? 'chips4' : 'chips2';
    }
  },
  methods: {
    changeChipIndex (value) {
      this.selectedChipIndex = value
      this.$emit('changeChipIndex', value)
    },
    refreshChips: function() {

    }
  },
  mounted: function() {
    this.refreshChips();
  },
  created: function() {

  },
  unmounted: function() {

  }
}
</script>