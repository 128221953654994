const COLOR_PLAYER = "#0055ff";
const COLOR_BANKER = "#ff0000";
const COLOR_LINE = "#e5e5e5";
const COLOR_TIE = "#5baf00";

const BREAK_LEFTORRIGHT = 1;

export default class HistorySixDrawer {
    constructor(api) {
        this.$API = api
        this.ctx = null
        this.x = 0
        this.y = 0
        this.width = 0
        this.height = 0
        this.cols = 0
        this.rows = 0
        this.colors = [COLOR_PLAYER, COLOR_BANKER, COLOR_TIE]
        this.origins = null
        this.drawCells = null
        this.deltaCols = 0
        this.breakLeftOrRight = BREAK_LEFTORRIGHT
        this.cellPadding = 1
        this.lineWidth = 1
    }

    cellWidth () {
        return this.width / this.cols;
    }
    cellHeight () {
        return this.height / this.rows;
    }

    init (ctx, x, y, w, h, cols, rows) {
        this.ctx = ctx;
        this.x = x;
        this.y = y;
        this.width = w;
        this.height = h;
        this.cols = cols;
        this.rows = rows;

        this.ctx.clearRect(this.x, this.y, this.width, this.height);
        let i;

        this.ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = COLOR_LINE;
        for (i = 0; i < this.cols; i++) {
            ctx.moveTo(this.x + this.cellWidth() * (i + 1), this.y);
            ctx.lineTo(this.x + this.cellWidth() * (i + 1), this.y + this.height);
            ctx.stroke();
        }
        for (i = 0; i < this.rows; i++) {
            ctx.moveTo(this.x, this.y + this.cellHeight() * (i + 1));
            ctx.lineTo(this.x + this.width, this.y + this.cellHeight() * (i + 1));
            ctx.stroke();
        }
    }

    updateOriginal (hists) {
        this.origins = [];

        let iRow = 0;
        let iCol = -1;
        let iPrevResult = -1;
        let i, iResult;
        let iPrevTieCount = 0, iTieCount = 0; // add

        for (i = 0; i < hists.length; i++) {
            iResult = -1
            if (hists[i] === '10' || hists[i] === '11' || hists[i] === '12' || hists[i] === '13') {
                iResult = 1 // 뱅
            } else if (hists[i] === '20' || hists[i] === '21' || hists[i] === '22' || hists[i] === '23') {
                iResult = 2 // 타이
            } else if (hists[i] === '30' || hists[i] === '31' || hists[i] === '32' || hists[i] === '33') {
                iResult = 0 // 플레이어
            }

            const ppair = hists[i] === '12' || hists[i] === '13' || hists[i] === '22' || hists[i] === '23' || hists[i] === '32' || hists[i] === '33'
            const bpair = hists[i] === '11' || hists[i] === '13' || hists[i] === '21' || hists[i] === '23' || hists[i] === '31' || hists[i] === '33'

            // 플, 뱅이면...
            if (iResult !== 2) {
                if (iPrevResult !== iResult) {
                    iCol++;
                    iRow = 0;
                }
                else {
                    iRow++;
                }

                // add from
                iTieCount = 0;
                if (iPrevTieCount > 0) {
                    iCol = iRow = 0;
                    this.origins = [];
                    iTieCount = iPrevTieCount;
                }
                iPrevTieCount = -1;
                // add to

                if (void 0 === this.origins[iCol]) this.origins[iCol] = [];
                if (void 0 === this.origins[iCol][iRow]) this.origins[iCol][iRow] = {
                    tieCount: iTieCount, result: iResult, ppair, bpair
                };

                iPrevResult = iResult;
            // 타이이면...
            } else {
                if (iPrevTieCount >= 0) iPrevTieCount++;
                if (iCol < 0) {
                    this.origins[0] = [];
                    this.origins[0][iRow] = { tieCount: 1, result: 2, ppair, bpair };
                    iPrevResult = 2;
                    iCol = 0;
                } else {
                    this.origins[iCol][iRow].tieCount++;
                }
            }
        }
    }

    getNextRowCol (tf, pos) {
        if (tf) {
            pos.row++;
            if (pos.row >= this.rows || void 0 !== this.drawCells[pos.col][pos.row] || this.deltaCols > 0) {
                pos.row--;
                pos.col += this.breakLeftOrRight;
                this.deltaCols++;
                // 만일 왼쪽으로 꺽이다가 왼쪽 끝에 도달햇거나 혹은 꺽이여가는 쪽에 이미 셀이 존재하면 반대방향으로 뻗어나간다.
                if (pos.col < 1 || (this.drawCells[pos.col] !== void 0 && this.drawCells[pos.col][pos.row] !== void 0) ||
                    (this.drawCells[pos.col + this.breakLeftOrRight] !== void 0 && this.drawCells[pos.col + this.breakLeftOrRight][pos.row] !== void 0)) {
                    // 이미 꺾인 셀들을 반대방향으로 돌린다.
                    for (let i = 1; i < this.deltaCols; i++) {
                        var cell = this.drawCells[pos.col - i * this.breakLeftOrRight][pos.row];
                        if (this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight] === void 0) this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight] = [];
                        this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight][pos.row] = cell;
                        this.drawCells[pos.col - i * this.breakLeftOrRight][pos.row] = undefined;
                    }
                    // 계산할때 이용하는 변수는 반대로 설정한다.
                    this.breakLeftOrRight = this.breakLeftOrRight == 1 ? -1 : 1;

                    pos.col += 2 * this.deltaCols * this.breakLeftOrRight;
                }
            }
        } else {
            pos.col = pos.col - this.breakLeftOrRight * this.deltaCols + 1;
            pos.row = 0;
            this.deltaCols = 0;
            // 결과가 꺽이엿으면 방향을 다시 본래대로 설정한다.
            this.breakLeftOrRight = BREAK_LEFTORRIGHT;
        }
    }

    redraw (hists, isDT, dtColor) {
        this.updateOriginal(hists);

        this.drawCells = [];
        this.deltaCols = 0;

        let iOrgCol, iOrgRow;
        let iPrevResult = -1;
        let curPos = { row: 0, col: 0 };
        for (iOrgCol = 0; iOrgCol < this.origins.length; iOrgCol++) {
            for (iOrgRow = 0; iOrgRow < this.origins[iOrgCol].length; iOrgRow++) {
                if (iPrevResult >= 0) {
                    this.getNextRowCol(iPrevResult == this.origins[iOrgCol][iOrgRow].result, curPos);
                }
                if (void 0 === this.drawCells[curPos.col]) this.drawCells[curPos.col] = [];
                this.drawCells[curPos.col][curPos.row] = this.origins[iOrgCol][iOrgRow];
                iPrevResult = this.origins[iOrgCol][iOrgRow].result;
            }
        }

        this.colors = isDT && dtColor ? [COLOR_BANKER, COLOR_PLAYER, COLOR_TIE] : [COLOR_PLAYER, COLOR_BANKER, COLOR_TIE];

        let iDeltaCol = (this.drawCells.length > this.cols) ? this.drawCells.length - this.cols : 0;
        for (let iCol = iDeltaCol; iCol < this.drawCells.length; iCol++) {
            for (let iRow = 0; iRow < this.drawCells[iCol].length; iRow++) {
                let clr;
                if (void 0 === this.drawCells[iCol][iRow] || void 0 === (clr = this.colors[this.drawCells[iCol][iRow].result])) continue;

                let x = this.cellWidth() / 2 + (iCol - iDeltaCol) * this.cellWidth();
                let y = this.cellHeight() / 2 + iRow * this.cellHeight();

                this.$API.util.drawCircle(this.ctx, this.x + x, this.y + y, this.cellWidth() / 2 - this.cellPadding, this.lineWidth, clr);

                if (!isDT) {
                    if (this.drawCells[iCol][iRow].ppair) {
                        this.$API.util.drawCircle(this.ctx, this.x + (iCol + 1 - iDeltaCol) * this.cellWidth() - this.cellPadding * 2, this.y + (iRow + 1) * this.cellHeight() - this.cellPadding * 2, this.cellWidth() * 0.15, 0, "#ffffff", true);
                        this.$API.util.drawCircle(this.ctx, this.x + (iCol + 1 - iDeltaCol) * this.cellWidth() - this.cellPadding * 2, this.y + (iRow + 1) * this.cellHeight() - this.cellPadding * 2, this.cellWidth() * 0.1, 0, COLOR_PLAYER, true);
                    }
                    if (this.drawCells[iCol][iRow].bpair) {
                        this.$API.util.drawCircle(this.ctx, this.x + (iCol - iDeltaCol) * this.cellWidth() + this.cellPadding * 2, this.y + iRow * this.cellHeight() + this.cellPadding * 2, this.cellWidth() * 0.15, 0, "#ffffff", true);
                        this.$API.util.drawCircle(this.ctx, this.x + (iCol - iDeltaCol) * this.cellWidth() + this.cellPadding * 2, this.y + iRow * this.cellHeight() + this.cellPadding * 2, this.cellWidth() * 0.1, 0, COLOR_BANKER, true);
                    }
                }

                let iTieCount = this.drawCells[iCol][iRow].tieCount;
                if (iTieCount > 0) {
                    this.$API.util.drawText(this.ctx, this.x + x, this.y + y, iTieCount, this.cellWidth() - this.cellPadding * 2, COLOR_TIE);
                }
            }
        }
    }
}